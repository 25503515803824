import React, { useEffect } from "react";
import { FaFilePdf, FaCheck } from "react-icons/fa";
import { AiFillCaretRight } from "react-icons/ai";
import "../index.css";
import capability from "../Assets/Docs/capability.pdf";

import gsa from "../Assets/Img/gsa_contracts.png";
// import cmmi from "../Assets/Img/cmmi1.png";
import MediaQuery from "react-responsive";
import iso from "../Assets/Img/iso.png";
import A2 from "../Assets/contracts/A2.png";

export default function Capabilities() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="container"
        style={{
          boxShadow: "0px 0px 10px rgba(51, 51, 51, 0.04)",
          marginTop: "70px",
        }}
      >
        <h2 className="col-title" style={{ borderBottom: "1px solid black" }}>
          CAPABILITIES
        </h2>
        <p>
          <span
            style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}
          >
            Company Overview
          </span>
          <a
            style={{
              padding: "5px 8px",
              backgroundColor: "#1c2951",
              float: "right",
              color: "#fff",
            }}
            href={capability}
            target="_blank"
            rel="noreferrer"
          >
            <FaFilePdf /> Download Capabilities
          </a>
        </p>
        <br />
        <p className="mobile-responsive">
          Eigennet LLC is an ISO 9001:2015-certified small business specializing
          in AI-driven IT services, particularly in document processing,
          digitization, and records management for government and commercial
          organizations. Our services stand out through innovative technological
          solutions that integrate AI and Machine Learning to automate complex
          tasks, ensuring reliability, accuracy, and cost-effectiveness. In
          addition to IT services, we provide management and administrative
          consulting, as well as audit and compliance monitoring, customized to
          meet the specific needs of our client organizations.
        </p>
        <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
          Business Process Areas:
        </p>

        <table
          style={{
            width: "100%",
            border: "0px solid #e1e0e0",
            background: "#fff",
            marginBottom: "10px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> IT Professional
                Services
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Document Conversion
                Services (Electronic &amp; Physical)
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Documents &amp; Records
                Management
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Compliance Monitoring
                &amp; Solutions
              </td>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> Digital Imaging
                Services
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Copyright-Related Legal
                Services
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Management Consulting{" "}
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Paralegal Services For
                Copyright
                <br />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            width: "100%",
            border: "0px solid #e1e0e0",
            background: "#fff",
            marginBottom: "10px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "70%",
                  verticalAlign: "top",
                }}
              >
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> GSA:</strong>{" "}
                <a
                  href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET%2C+LLC&executeQuery=YES"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  47QTCA19D00A2
                  {/* , 47QTCA19D00N3 */}
                </a>
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> GSA Categories:</strong> 493110RM, 518210C, 518210DC,
                518210ERM, 541110, 541511, 54151HEAL, 54151S, 541611, 541690,
                561439, OLM and 561320SBSA
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> UEI:</strong> XTA5EN9KNJN5 / <strong>DUNS:</strong>{" "}
                080267682 / <strong>Cage Code:</strong> 7MSQ9
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> PSC CODE:</strong> AJ11 R&amp;D
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> NAICS CODES:</strong> 541511, 517410, 518210, 519130,
                541330, 541519, 541611, 541612, 541614,&nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;541618, 541690, 541715,
                541990, 611420
              </td>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "30%",
                }}
              >
                <a
                  href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET LLC&executeQuery=YES"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <img
                    className="alignleft"
                    src={A2}
                    alt="ab2"
                    style={{ border: "10px solid #fff", width: "90%" }}
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Our Capabilities
          </p>
          <p>
            <strong style={{ color: "#15244f" }}>
              AI &amp; Machine Learning:{" "}
            </strong>
            Eigennet develops AI-driven solutions for predictive modeling,
            process automation, and compliance monitoring. By leveraging
            advanced algorithms and machine learning, Eigennet enhances
            operational efficiency, strengthens accuracy, with improved
            cost-effectiveness for various IT services.
          </p>
          {/* <p>
          <strong style={{ color: "#15244f" }}>
            Natural Language Processing:{" "}
          </strong>
          Automation of text processing in several languages and checking for
          compliance per federal regulations with the use of algorithms and
          custom NLP methods.
        </p> */}

          <p>
            <strong style={{ color: "#15244f" }}>
              Compliance & Redaction with NLP:{" "}
            </strong>
            Eigennet enables organizations to adhere to regulatory compliance
            through automated text processing and secure redaction of sensitive
            Personally Identifiable Information (PII) using AI-driven Natural
            Language Processing (NLP), enhancing document security by detecting
            critical information and enforcing privacy standards.
          </p>

          {/* <p>
          <strong style={{ color: "#15244f" }}>Computer Vision: </strong>
          Development of computers and software systems to derive meaningful
          information from digital images, documents, videos, and other visual
          inputs. Taking subsequent actions and making recommendations to
          multiple agencies, based on the information processed.
        </p> */}
          <p>
            <strong>Document & Image Processing :</strong>
            Eigennet leverages state-of-the-art AI-based Intelligent Character
            Recognition (ICR) for document processing, which goes beyond
            traditional OCR to accurately process scanned documents. By
            understanding the context of each document, Eigennet’s ICR-powered
            solutions can handle a wide range of document types, from simple to
            complex, even with varying levels of legibility.
          </p>
          <p>
            <strong style={{ color: "#15244f" }}>
              Statistical Analysis :{" "}
            </strong>
            Eigennet applies AI-powered analytics and predictive modeling to
            optimize operations, offering valuable insights for more informed
            decision-making. Using advanced statistical methodologies, we enable
            benchmarking, and data-driven strategies that drive operational
            efficiency. Our solutions help organizations identify trends, assess
            risks, improve performance and maintain a competitive edge.
          </p>
          <p>
            <strong style={{ color: "#15244f" }}>
              Custom Application Development :{" "}
            </strong>
            Eigennet specializes in custom application development and
            integration using technologies such as .NET, Java, Ruby, and Python.
            Eigennet offers both managed services and distributed development to
            build critical applications tailored to various business needs. Our
            expertise extends to addressing challenges like multi-tenancy,
            operational support, requirements management, and security,
            providing a cohesive and secure platform.
          </p>
          <p>
            <strong style={{ color: "#15244f" }}>
              Robotic Process Automation (RPA) :{" "}
            </strong>
            Eigennet leverages RPA to automate repetitive tasks, reducing manual
            effort and enhancing operational efficiency. By integrating
            AI-driven automation, Eigennet streamlines high-volume workflows,
            improves accuracy, and accelerates processing across industries,
            enabling organizations to optimize productivity and resource
            utilization.
          </p>
          {/* <p>
          <strong style={{ color: "#15244f" }}>
            Custom Application Development:{" "}
          </strong>{" "}
          Development and integration of critical applications utilizing .NET,
          Java, Ruby, Python and other technologies through managed services or
          distributed development. We will assist in navigating issues of
          multi-tenancy, operational support, requirements management and
          security to ensure a cohesive platform while keeping data secure.
        </p> */}
        </div>

        <MediaQuery minWidth={930}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Past Performances
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong> IRS – eOPF Secure Digital Transformation</strong> Eigennet
            digitizes IRS Electronic Official Personnel Folder (eOPF) records
            using AI-driven anomaly detection, automated indexing, and secure
            data processing. By ensuring compliance with NIST cybersecurity
            standards and IRS regulations, Eigennet enhances data security,
            accuracy, and accessibility, streamlining document retrieval while
            maintaining strict protection protocols.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>
              {" "}
              Defense Health Agency (DHA) – HR Administrative Support
            </strong>{" "}
            Eigennet provides administrative support services for the Uniform
            Business Office (UBO) within the Resource Management Department at
            U.S. Naval Hospital Guam, assisting with financial processing,
            records management, claims handling, and compliance monitoring.
            Eigennet ensures accurate documentation, improves workflow
            efficiency, and supports UBO operations through effective
            coordination and process improvement.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>
              {" "}
              Library of Congress – Recordation Backlog Reduction{" "}
            </strong>
            Eigennet provided digitization and metadata extraction services to
            the U.S. Copyright Office, converting physical and electronic
            records into structured digital data. Using OCR and automation,
            Eigennet extracted and formatted metadata to meet Electronic Title
            List (ETL) standards, ensuring accurate indexing and retrieval. The
            project involved processing copyright records, performing quality
            checks, and verifying compliance with recordation requirements.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>IRS – DVD Digitization & Data Migration </strong>Eigennet
            provided migration services to the IRS Tax Exempt and Government
            Entities (TE/GE) division, converting over 700,000 files from 5,078
            DVDs into a cloud-based repository. Using advanced automation and
            metadata extraction techniques, Eigennet ensured seamless digital
            access and retrieval while maintaining compliance with IRS data
            security standards. Innovative solutions, including automated
            hardware processing, streamlined the digitization workflow,
            improving efficiency and achieving over 98% accuracy in data
            migration.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>
              {" "}
              American Battle Monuments Commission – Records Digitization{" "}
            </strong>{" "}
            Eigennet digitized and indexed 150,000 historical records, including
            internment cards, reports, and architectural documents for ABMC. The
            project adhered to FADGI 3-Star standards, ensuring high-quality
            imaging, metadata extraction, and structured digital organization.
            Eigennet implemented rigorous quality control measures and
            compliance protocols to enhance record accessibility and long-term
            preservation while maintaining the integrity of historical
            documents.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>
              {" "}
              U.S. Commission for America’s Heritage Abroad – Document
              Digitization
            </strong>{" "}
            Eigennet digitized approximately 150,000 historical records for the
            U.S. Commission for the Preservation of America's Heritage Abroad,
            ensuring high-resolution scanning, precise metadata extraction, and
            compliance with NARA preservation standards. Utilizing specialized
            microfiche and high-speed document scanners, Eigennet maintained
            accuracy and quality throughout the process .
          </p>
        </MediaQuery>

        <MediaQuery maxWidth={929}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Past Performances
          </p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong> IRS – eOPF Secure Digital Transformation</strong>{" "}
                    Eigennet digitizes IRS Electronic Official Personnel Folder
                    (eOPF) records using AI-driven anomaly detection, automated
                    indexing, and secure data processing. By ensuring compliance
                    with NIST cybersecurity standards and IRS regulations,
                    Eigennet enhances data security, accuracy, and
                    accessibility, streamlining document retrieval while
                    maintaining strict protection protocols.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      {" "}
                      Defense Health Agency (DHA) – HR Administrative Support
                    </strong>{" "}
                    Eigennet provides administrative support services for the
                    Uniform Business Office (UBO) within the Resource Management
                    Department at U.S. Naval Hospital Guam, assisting with
                    financial processing, records management, claims handling,
                    and compliance monitoring. Eigennet ensures accurate
                    documentation, improves workflow efficiency, and supports
                    UBO operations through effective coordination and process
                    improvement.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      {" "}
                      Library of Congress – Recordation Backlog Reduction{" "}
                    </strong>
                    Eigennet provided digitization and metadata extraction
                    services to the U.S. Copyright Office, converting physical
                    and electronic records into structured digital data. Using
                    OCR and automation, Eigennet extracted and formatted
                    metadata to meet Electronic Title List (ETL) standards,
                    ensuring accurate indexing and retrieval. The project
                    involved processing copyright records, performing quality
                    checks, and verifying compliance with recordation
                    requirements.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong> IRS – DVD Digitization & Data Migration</strong>{" "}
                    Eigennet provided migration services to the IRS Tax Exempt
                    and Government Entities (TE/GE) division, converting over
                    700,000 files from 5,078 DVDs into a cloud-based repository.
                    Using advanced automation and metadata extraction
                    techniques, Eigennet ensured seamless digital access and
                    retrieval while maintaining compliance with IRS data
                    security standards. Innovative solutions, including
                    automated hardware processing, streamlined the digitization
                    workflow, improving efficiency and achieving over 98%
                    accuracy in data migration.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      American Battle Monuments Commission – Records
                      Digitization{" "}
                    </strong>{" "}
                    Eigennet digitized and indexed 150,000 historical records,
                    including internment cards, reports, and architectural
                    documents for ABMC. The project adhered to FADGI 3-Star
                    standards, ensuring high-quality imaging, metadata
                    extraction, and structured digital organization. Eigennet
                    implemented rigorous quality control measures and compliance
                    protocols to enhance record accessibility and long-term
                    preservation while maintaining the integrity of historical
                    documents.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      {" "}
                      U.S. Commission for America’s Heritage Abroad – Document
                      Digitization
                    </strong>{" "}
                    Eigennet digitized approximately 150,000 historical records
                    for the U.S. Commission for the Preservation of America's
                    Heritage Abroad, ensuring high-resolution scanning, precise
                    metadata extraction, and compliance with NARA preservation
                    standards. Utilizing specialized microfiche and high-speed
                    document scanners, Eigennet maintained accuracy and quality
                    throughout the process
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={930}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Differentiators
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} />
            Experienced Personnel – Key staff members have 15+ years of IT and
            relevant experience, working in high-security client environments to
            support mission-critical operations.
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} /> AI-Driven Solutions –
            Developed proprietary tools such as the RFP Proposal Automation
            System for the commercial market and DocuData.AI for federal
            agencies, enabling automated document processing, conversion, and
            compliance enforcement.
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} /> Certified Expertise – Our
            team holds 100% industry-recognized certifications, including PMP,
            ITIL, AWS Cloud, Azure Cloud, and Six Sigma, ensuring top-tier
            project execution.
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} /> Proven Rapid Onboarding – A
            streamlined hiring and integration process enables us to deploy
            highly skilled professionals quickly, ensuring seamless
          </p>
        </MediaQuery>

        <MediaQuery maxWidth={929}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Differentiators
          </p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    Experienced Personnel – Key staff members have 15+ years of
                    IT and relevant experience, working in high-security client
                    environments to support mission-critical operations.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    {" "}
                    AI-Driven Solutions – Developed proprietary tools such as
                    the RFP Proposal Automation System for the commercial market
                    and DocuData.AI for federal agencies, enabling automated
                    document processing, conversion, and compliance enforcement.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    {" "}
                    Certified Expertise – Our team holds 100%
                    industry-recognized certifications, including PMP, ITIL, AWS
                    Cloud, Azure Cloud, and Six Sigma, ensuring top-tier project
                    execution.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    {" "}
                    Proven Rapid Onboarding – A streamlined hiring and
                    integration process enables us to deploy highly skilled
                    professionals quickly, ensuring seamless
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>

        <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
          Certifications:
        </p>
        <table
          style={{
            width: "100%",
            border: "0px solid #e1e0e0",
            background: "#fff",
            marginBottom: "10px",
          }}
        >
          <tbody>
            <tr>
              {/* <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                  color: "#1c2951",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                CMMI Certifications
              </td> */}
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                  color: "#1c2951",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                ISO Certifications
              </td>
            </tr>
            <tr>
              {/* <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> CMMI Development Level
                2 Certification
                <br />
                <FaCheck style={{ color: "#84c000" }} /> CMMI Service Level 2
                Certification
              </td> */}
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> ISO 9001:2015
              </td>
            </tr>
          </tbody>
        </table>

        <MediaQuery minWidth={930}>
          <p>
            {/* <img src={cmmi} style={{ width: "50%" }} alt="ab2" /> */}
            <img
              src={iso}
              style={{ width: "12%", marginTop: "20px" }}
              alt="ab2"
            />
          </p>
          <div className="clear">&nbsp;</div>
        </MediaQuery>

        <MediaQuery maxWidth={930}>
          <p>
            {/* <img src={cmmi} style={{ width: "100%" }} alt="ab2" /> */}
            <img
              src={iso}
              style={{ width: "30%", marginTop: "20px" }}
              alt="ab2"
            />
          </p>
          <div className="clear">&nbsp;</div>
        </MediaQuery>
      </div>
      <br />
      <br />
    </>
  );
}
